/* Estilo general */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
}

/* Contenedor principal */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Contenedor del iframe */
.powerbi-report-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

/* Ajustes para el iframe */
.report-style-class {
  width: 100%;
  height: 100%;
  position: relative;
  border: none;
  margin: 0;
  padding: 0;
}

/* Ocultar la barra de prueba gratuita en el iframe */
iframe {
  position: relative;
}

iframe *[aria-label*="versión de prueba gratuita"],
iframe [class*="notification-bar"],
iframe div[style*="trial"],
iframe span[style*="trial"] {
  display: none !important;
  visibility: hidden !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
  .report-style-class {
    height: 70vh;
    width: 95vw;
  }
}

/* Media Queries para pantallas grandes */
@media (min-width: 769px) {
  .report-style-class {
    height: 100vh;
    width: 100%;
  }
}
.report-style-class {
  position: relative;
  width: 100%;
  height: calc(100% + 50px); /* Extiende la altura */
  margin-top: -50px; /* Empuja hacia arriba */
  overflow: hidden;
}
