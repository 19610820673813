/* PowerBIReport.css */

.iframe-container {
    position: relative;
    height: 100vh; /* Ajusta según tus necesidades */
    width: 100%;
  }
  
  .notification-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px; /* Ajusta según la altura de la barra de notificación */
    background: white; /* O el color de fondo que prefieras */
    z-index: 1000;
    pointer-events: none;
  }